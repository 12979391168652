import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import svg404 from '../assets/images/404.svg'

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO title="404: Not Found" />
      <div style={{height: '70vh'}} className="w-full flex flex-col items-center justify-center">
        <img src={svg404} alt="404 img" className='lg:w-2/5 md:w-2/3 w-4/5'/>
        <h1 className="md:text-4xl text-3xl mx-4 my-6 font-logoFont">You seem to be lost, head to the <Link to="/">main page!</Link></h1>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
